import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import router from './router'
// import service from './service'
//导入全局样式表
import './assets/css/global.css'
import * as echarts from 'echarts'

axios.defaults.baseURL= 'https://cf-v1.uapis.cn/api'
axios.interceptors.request.use((config) => {
  //处理请求头
  config.headers.HTTP_TOKEN = localStorage.getItem("token")
  return config //放行拦截,必须放行
})
Vue.prototype.axios = axios
const backend = axios.create({
  baseURL: 'https://panel.backend.likeyoudo.top:30080/prod-api'
})

backend.interceptors.request.use((config)=>{
  //添加token请求
  config.headers.Authorization = localStorage.getItem("back_token")
  return config
})
Vue.prototype.$http = backend
Vue.prototype.$echarts = echarts;

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
